const animatedCursor = () => {
  const cursor = document.querySelector(".followCursor")

  const detectMobile = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ]

    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem)
    })
  }

  if (detectMobile()) {
    document.body.classList.add("mobile")
    return
  }

  const changePlace = (e) => {
    cursor.style.top = e.clientY + "px"
    cursor.style.left = e.clientX + "px"
  }

  window.addEventListener("mousemove", changePlace)
}

export default animatedCursor

function debounce(func, wait) {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}
