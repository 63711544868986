export const navWalker = () => {
  const gansaWalker = [...document.querySelectorAll(".gansa_walker a")],
    url = window.location.href,
    dropdown = [...document.querySelectorAll(".dropdown")]

  gansaWalker.map((item) => {
    if (item.href === url) {
      item.closest("li").classList.add("active")
    }
  })

  const removeClickListener = () => {
    document.removeEventListener("click", hideOnClickOutside)
  }

  const hideOnClickOutside = (e) => {
    if (e.target && !e.target.closest(".dropdown")) {
      const activeDropdown = document.querySelector(".dropdown-menu--active")
      activeDropdown.style.height = 0
      activeDropdown.classList.remove("dropdown-menu--active")
      removeClickListener()
    }
  }

  if (dropdown) {
    dropdown.forEach((item) => {
      const btn = item.querySelector(".gansa_walker__unfold")

      btn.addEventListener("click", (e) => {
        e.preventDefault()
        e.stopImmediatePropagation()

        const dropdownMenu = item.querySelector(".dropdown-menu")
        item.classList.toggle("dropdown--active")

        const paddingTop = parseInt(
            window.getComputedStyle(dropdownMenu).paddingTop
          ),
          paddingBottom = parseInt(
            window.getComputedStyle(dropdownMenu).paddingBottom
          ),
          startValue = paddingTop + paddingBottom

        const isActive = item.classList.contains("dropdown--active")
        if (isActive) {
          const maxHeight = [...dropdownMenu.childNodes].reduce(
            (prev, current) => {
              const currentHeight = current.offsetHeight
              return prev + currentHeight
            },
            startValue
          )

          dropdownMenu.style.height = maxHeight + "px"
        } else {
          dropdownMenu.style.height = 0
        }

        // document.addEventListener("click", hideOnClickOutside)
      })
    })
  }
}
