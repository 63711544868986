export default () => {
  const scrollTop = document.querySelector("#scroll-top"),
    main = document.querySelector("main")

  if (!scrollTop) return

  const toggleScrollTop = () => {
    if (main.getBoundingClientRect().top < -399) {
      scrollTop.classList.add("show")
      window.removeEventListener("scroll", toggleScrollTop)
    }
    // else {
    //   scrollTop.classList.remove("show")
    // }
  }

  window.addEventListener("scroll", debounce(toggleScrollTop, 50))
}

function debounce(func, wait) {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), wait)
  }
}
