import PhotoSwipe from "photoswipe"
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default"

export default () => {
  const allImagesFromSlides = document.querySelectorAll(
      ".js-product-slider .glide__slide > img"
    ),
    pswpElement = document.querySelectorAll(".pswp")[0],
    pswpInitBtn = document.querySelector(
      ".js-product-slider .js-photoswipe-init"
    ),
    thumbnails = document.querySelectorAll(".glide__bullet")

  let photoSwipeImagesArray = []

  if (allImagesFromSlides && pswpElement) {
    allImagesFromSlides.forEach((image, index) => {
      const src = image.src,
        w = parseInt(image.width, 10),
        h = parseInt(image.height, 10)

      photoSwipeImagesArray.push({ src, w, h })

      pswpInitBtn.addEventListener("click", (e) => {
        e.preventDefault()
        e.stopImmediatePropagation()
        new PhotoSwipe(
          pswpElement,
          PhotoSwipeUI_Default,
          photoSwipeImagesArray,
          {
            index: index,
          }
        ).init()
      })

      allImagesFromSlides.forEach((item, index) => {
        item.addEventListener("click", (e) => {
          e.preventDefault()
          e.stopImmediatePropagation()
          new PhotoSwipe(
            pswpElement,
            PhotoSwipeUI_Default,
            photoSwipeImagesArray,
            {
              index: index,
            }
          ).init()
        })
      })

      thumbnails.forEach((item, index) => {
        item.addEventListener("click", (e) => {
          e.preventDefault()
          e.stopImmediatePropagation()
          new PhotoSwipe(
            pswpElement,
            PhotoSwipeUI_Default,
            photoSwipeImagesArray,
            {
              index: index,
            }
          ).init()
        })
      })
    })
  }
}
