export const burger = () => {
  const menuBtn = document.querySelector(".navbar-toggler"),
    butonAnim = document.querySelector(".menu-btn"),
    navigation = document.querySelector(".mobileNav__wrapper"),
    body = document.querySelector("html")

  let menuOpen = false

  if (menuBtn) {
    menuBtn.addEventListener("click", () => {
      if (!menuOpen) {
        butonAnim.classList.add("open")
        menuBtn.setAttribute("aria-expanded", "true")
        navigation.classList.add("show")
        menuOpen = true
        body.style.overflow = "hidden"
      } else {
        butonAnim.classList.remove("open")
        menuBtn.setAttribute("aria-expanded", "false")
        navigation.classList.remove("show")
        menuOpen = false
        body.style.overflow = "auto"
      }
    })
  }
}
