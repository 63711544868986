import Glide from "@glidejs/glide"

export default () => {
  const glide = document.querySelector(".glide-hero")

  if (glide) {
    const current = document.querySelector(".hero__current-slide .current"),
      prevSlide = document.querySelector(".hero__prev-slide"),
      nextSlide = document.querySelector(".hero__next-slide"),
      track = document.querySelector(".glide__track"),
      glideType = glide.dataset.carousel ? "carousel" : "slider",
      glideAutoplay = glide.dataset.carousel ? 5000 : false

    let heroSlider = new Glide(".hero .glide", {
      type: glideType,
      startAt: 0,
      perView: 1,
      rewind: false,
      autoplay: glideAutoplay,
      animationDuration: 1000,
    })

    const calculateSliderHeight = () => {
      const activeSlide = document.querySelector(".glide__slide--active")
      track.style.height = activeSlide.offsetHeight + "px"
    }

    heroSlider.on("build.after", () => {
      calculateSliderHeight()
    })

    heroSlider.on("run.after", () => {
      current.textContent = heroSlider.index + 1

      calculateSliderHeight()
    })

    heroSlider.on("resize", () => {
      calculateSliderHeight()
    })

    heroSlider.mount()

    prevSlide.addEventListener("click", () => {
      heroSlider.go("<")
    })
    nextSlide.addEventListener("click", () => {
      heroSlider.go(">")
    })
  }

  const productsGlide = document.querySelector(".glide-products")

  if (productsGlide) {
    const prevSlide = document.querySelector(".get-mirror__prev-slide"),
      nextSlide = document.querySelector(".get-mirror__next-slide")

    const productsSlider = new Glide(".glide-products", {
      type: "slider",
      perView: 4,
      peek: {
        before: 0,
        after: 75,
      },
      startAt: 0,
      rewind: false,
      bound: true,
      breakpoints: {
        1024: {
          perView: 3,
        },
        768: {
          perView: 2,
        },
        565: {
          perView: 1,
        },
      },
    })

    prevSlide.addEventListener("click", () => {
      productsSlider.go("<")
    })

    nextSlide.addEventListener("click", () => {
      productsSlider.go(">")
    })

    productsSlider.mount()
  }

  const isElement = document.querySelector(".js-product-slider")

  if (isElement) {
    const slider = new Glide(".js-product-slider", {
      type: "slider",
      perView: 1,
      gap: 0,
    })

    slider.on("mount.after", () => {
      const activeSlideHeight = document.querySelector(
          ".glide__slide--active > img"
        ).height,
        targetNode = document.querySelector(".glide__track")

      targetNode.style.height = activeSlideHeight + "px"
    })

    slider.on("run.after", () => {
      const activeSlideHeight = document.querySelector(
          ".glide__slide--active > img"
        ).height,
        targetNode = document.querySelector(".glide__track")

      targetNode.style.height = activeSlideHeight + "px"
    })

    slider.on("resize", () => {
      const activeSlideHeight = document.querySelector(
          ".glide__slide--active > img"
        ).height,
        targetNode = document.querySelector(".glide__track")

      targetNode.style.height = activeSlideHeight + "px"
    })

    slider.mount()

    const buttons = document.querySelectorAll(".glide__bullet")

    buttons.forEach((button) => {
      button.addEventListener("click", () => {
        const slideID = button.dataset.glideDir
        slider.go(slideID)
      })
    })
  }

  const whyUsGlide = document.querySelector(".js-why-us-glide")

  if (whyUsGlide) {
    const track = document.querySelector(".js-why-us-glide .glide__track"),
      current = document.querySelector(
        ".js-why-us-glide .hero__current-slide .current"
      )

    const calculateSliderHeight = () => {
      const activeSlide = document.querySelector(
        ".js-why-us-glide .glide__slide--active"
      )

      track.style.height = activeSlide.offsetHeight + "px"
    }

    let heroSlider = new Glide(".js-why-us-glide", {
      type: "carousel",
      startAt: 0,
      perView: 1,
      rewind: false,
      autoplay: 4000,
      animationDuration: 1000,
    })

    heroSlider.on("build.after", () => {
      calculateSliderHeight()
    })

    heroSlider.on("run.after", () => {
      current.textContent = heroSlider.index + 1

      calculateSliderHeight()
    })

    heroSlider.mount()
  }
}
