export default () => {
  const openModal = document.querySelector(".js-modal-form"),
    modal = document.querySelector(".p-modal")

  if (!modal || !openModal) return

  const closeModal = document.querySelector(".js-modal-close")

  const detectClickOutisde = (e) => {
    if (e.target && !e.target.closest(".p-modal__content")) {
      modal.classList.remove("show")
      window.removeEventListener("click", detectClickOutisde)
    }
  }

  openModal.addEventListener("click", (e) => {
    e.preventDefault()
    e.stopImmediatePropagation()

    modal.classList.add("show")

    window.addEventListener("click", detectClickOutisde)
  })

  closeModal.addEventListener("click", () => {
    modal.classList.remove("show")

    window.removeEventListener("click", detectClickOutisde)
  })
}
