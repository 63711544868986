export default () => {
  const tabsButton = document.querySelector("#main-menu > li > button"),
    mainMenu = document.querySelector("#main-menu"),
    mainNav = document.querySelector("#main-nav")

  const detectClickOutside = (e) => {
    if (e.target && !mainNav.contains(e.target)) {
      closeTab(tabsButton)
      mainMenu.classList.remove("disable")
      document.body.removeEventListener("click", detectClickOutside)
    }
  }

  tabsButton.addEventListener("click", () => {
    if (tabsButton.getAttribute("aria-expanded") === "false") {
      openTab(tabsButton)
      mainMenu.classList.add("disable")
      document.body.addEventListener("click", detectClickOutside)
    } else {
      closeTab(tabsButton)
      mainMenu.classList.remove("disable")
      document.body.removeEventListener("click", detectClickOutside)
    }
  })
}

const openTab = (button) => {
  const target = button.getAttribute("aria-controls"),
    panel = document.querySelector(`#${target}`)

  button.setAttribute("aria-expanded", "true")
  button.style.pointerEvents = "none"

  panel.classList.add("show")
  panel.classList.add("collapsing")

  setTimeout(() => {
    const desiredHeight = panel.children[0].offsetHeight
    panel.style.height = desiredHeight + "px"
  })

  panel.addEventListener(
    "transitionend",
    () => {
      panel.classList.remove("collapsing")
      panel.style = ""
      button.style.pointerEvents = "auto"
    },
    {
      once: true,
    }
  )
}

const closeTab = (button) => {
  const target = button.getAttribute("aria-controls"),
    panel = document.querySelector(`#${target}`),
    desiredHeight = panel.children[0].offsetHeight

  button.setAttribute("aria-expanded", "false")
  button.style.pointerEvents = "none"
  panel.style.height = desiredHeight + "px"

  setTimeout(() => {
    panel.classList.add("collapsing")
    panel.style = ""
  })

  panel.addEventListener(
    "transitionend",
    () => {
      panel.classList.remove("collapsing")
      panel.classList.remove("show")
      button.style.pointerEvents = "auto"
    },
    {
      once: true,
    }
  )
}
