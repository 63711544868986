export default () => {
  const select = document.querySelector("[data-target='post-order']")

  if (select) {
    const optionsContainer = select.querySelector(".options-container"),
      selectBox = select.querySelector(".select-box"),
      options = select.querySelectorAll(".option:not(.defaultOption)"),
      inputsList = optionsContainer.querySelectorAll("input"),
      visibleInput = select.querySelector(".option.defaultOption p.label"),
      defaultInput = select.querySelector('[for="date-desc"]')

    // function to use in listener for listening click outside select box
    // if event noticed fold option list and remove listener
    const detectClickOutside = (e) => {
      if (e.target && !selectBox.contains(e.target)) {
        selectBox.classList.remove("active")
        optionsContainer.classList.remove("active")
        document.body.removeEventListener("click", detectClickOutside)
      }
    }

    // click on select expand option list
    select.addEventListener("click", () => {
      selectBox.classList.toggle("active")
      optionsContainer.classList.toggle("active")

      document.body.addEventListener("click", detectClickOutside)
    })

    // change href, depend on choosen element
    // it cause change in $wp_query ( Wordpress magic )
    options.forEach((item) => {
      const location = item.querySelector("input").value,
        attr = location?.split(",")

      // create link modifier depend on input value
      const modifier = `?orderby=${attr[0]}&order=${attr[1]}`

      // destructuring values from curent location
      const { protocol, hostname, port, pathname } = window.location

      // create path where we want add modifier
      // it's for exclude modifier if location is after previous change
      const path = `${protocol}//${hostname}:${port}${pathname}`
      item.addEventListener("click", () => {
        window.location.href = `${path}${modifier}`
      })
    })

    // verification if in current location have attributes passed to input value

    const currenLocation = window.location.href

    inputsList.forEach((item) => {
      const modifiers = item?.value?.split(",")
      if (
        modifiers &&
        currenLocation.includes(modifiers[0]) &&
        currenLocation.includes(modifiers[1])
      ) {
        // if we get matched input, change default label to label from this input
        visibleInput.textContent =
          item.parentNode.querySelector("label").textContent
        // hide matched input
        item.parentNode.style.display = "none"
      }
    })

    // on blog page remove option with same label as visible label

    if (visibleInput.textContent === defaultInput.textContent) {
      defaultInput.parentElement.style.display = "none"
    }
  }
}
