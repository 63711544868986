const image = document.querySelector(".customization__image"),
  replacement = document.querySelector(".customization__replacement"),
  options = document.querySelectorAll(".customization__option"),
  optionsContainer = document.querySelector(".customization__content")

export default () => {
  if (!image || !replacement || options?.length == 0) return

  console.log("message")

  options.forEach((option) => {
    option.addEventListener("mousemove", () => {
      image.style.display = "none"
      replacement.style.display = "block"
      replacement.src = option.dataset.replace
    })

    option.addEventListener("mouseover", () => {
      image.style.display = "none"
      replacement.style.display = "block"
      replacement.src = option.dataset.replace
    })
  })

  optionsContainer.addEventListener("mouseleave", () => {
    image.style.display = "block"
    replacement.style.display = "none"
  })
}
