export default () => {
  const closeInfoBar = document.querySelector(".info-bar__close"),
    infoBar = document.querySelector(".info-bar")

  if (closeInfoBar) {
    closeInfoBar.addEventListener("click", () => {
      infoBar.style.display = "none"
    })
  }
}
