import accordion from "./parts/accordion"
import { burger } from "./parts/burger"
import categorySelect from "./parts/categorySelect"
import formInput from "./parts/form-input"
import { navWalker } from "./parts/nav-walker"
import photoswipeCustom from "./parts/photoswipe-custom"
import productModal from "./parts/product-modal"
import scrollTop from "./parts/scroll-top"
import blogPostOrder from "./parts/blog-post-order"
import collapseMenu from "./parts/collapse-menu"
import searchBar from "./parts/search-bar"
import gsapAnim from "./parts/gsap-anim"
import glideAnim from "./parts/glide-anim"
import mobileMenu from "./parts/mobile-menu"
import infoBar from "./parts/info-bar"
import animatedCursor from "./parts/cursorAnimation"
import whyUsReplace from "./parts/why-us-replace"

document.addEventListener("DOMContentLoaded", () => {
  burger()
  navWalker()
  blogPostOrder()
  gsapAnim()
  glideAnim()
  formInput()
  accordion()
  photoswipeCustom()
  productModal()
  scrollTop()
  categorySelect()
  collapseMenu()
  searchBar()
  mobileMenu()
  infoBar()
  animatedCursor()
  navWalker()
  // whyUsReplace()
})
