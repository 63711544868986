export default () => {
  const searchBar = document.querySelector(".search-bar")
  if (searchBar) {
    const searchBarToggle = searchBar.querySelector(".js-open-form"),
      inputSearch = searchBar.querySelector(
        '.woocommerce-product-search input[type="search"]'
      ),
      inputSubmit = searchBar.querySelector(
        '.woocommerce-product-search button[type="submit"]'
      )
    const icon = searchBar.querySelector(
      ".search-bar__button > .search-bar__icon"
    )

    searchBarToggle.addEventListener("click", (e) => {
      e.preventDefault()
      e.stopImmediatePropagation()

      if (
        inputSearch.value !== "" &&
        searchBar.classList.contains("search-bar--active")
      ) {
        inputSubmit.click()
      }

      searchBar.classList.toggle("search-bar--active")
      icon.classList.toggle("search-bar__icon--active")
    })
  }

  const searchBarMobile = document.querySelector(".search-bar-mobile")

  if (searchBarMobile) {
    const openSearchBar = searchBarMobile.querySelector(".js-open-mobileform"),
      form = searchBarMobile.querySelector("form")

    openSearchBar.addEventListener("click", () => {
      form.classList.toggle("show")
    })
  }
}
