import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

export default () => {
  // gold elemnt in right sidebar
  const marker = document.querySelector(".left-aside__scroll-marker")
  if (marker) {
    gsap.set(".left-aside__scroll-marker", {
      yPercent: -100,
    })
    gsap
      .timeline({
        scrollTrigger: {
          // markers: true,
          trigger: "#page-header",
          start: "10% top",
          endTrigger: ".site-footer",
          end: "bottom bottom",
          scrub: 1,
          toggleActions: "restart complete none reverse",
          invalidateOnRefresh: true,
        },
      })
      .to(".left-aside__scroll-marker", {
        yPercent: 0,
      })
  }

  const animation1 = gsap.to("#main-nav", {
    yPercent: -100,
    duration: 0.4,
    ease: "ease-in-out",
    paused: true,
  })

  ScrollTrigger.create({
    trigger: "#main-nav",
    start: "10px top",
    end: 99999,
    invalidateOnRefresh: true,
    onUpdate: ({ direction, isActive }) => {
      if (direction == -1) {
        animation1.reverse()
      }
      if (direction == 1) {
        animation1.play()
      } else if (direction == 1 && isActive == true) {
        animation1.play()
      }
    },
  })

  if (document.querySelector(".info-bar")) {
    const animation2 = gsap.to(".info-bar", {
      y: -64,
      duration: 0.4,
      ease: "ease-in-out",
      paused: true,
    })

    ScrollTrigger.create({
      trigger: "#main-nav",
      start: "10px top",
      end: 99999,
      invalidateOnRefresh: true,
      onUpdate: ({ direction, isActive }) => {
        if (direction == -1) {
          animation1.reverse()
          animation2.reverse()
        }
        if (direction == 1) {
          animation1.play()
          animation2.play()
        } else if (direction == 1 && isActive == true) {
          animation1.play()
          animation2.play()
        }
      },
    })
  }

  if (document.querySelector(".background-text__text")) {
    gsap.to(".background-text__text", {
      scrollTrigger: {
        trigger: "#page-header",
        start: "10% top",
        endTrigger: ".site-footer",
        end: "bottom bottom",
        scrub: 4,
        invalidateOnRefresh: true,
      },
      xPercent: -100,
    })
  }

  const heroGlideImage = document.querySelector(".hero .glide img")

  if (heroGlideImage) {
    gsap.set(".hero .glide img", {
      y: 0,
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: "#page-header",
          start: "10% top",
          endTrigger: ".hero",
          end: "bottom top",
          scrub: 4,
          invalidateOnRefresh: true,
        },
      })
      .to(".hero .glide img", {
        y: 80,
      })
  }

  if (document.querySelector(".illumine")) {
    gsap.set(".illumine__main-image", {
      y: 0,
    })

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".illumine",
          start: "top center",
          end: "bottom top",
          scrub: 4,
          invalidateOnRefresh: true,
        },
      })
      .to(".illumine__main-image", {
        y: 70,
      })
  }

  if (document.querySelector(".get-mirror")) {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".get-mirror",
          start: "top 70%",
          // toggleActions: "restart none reverse reverse",
        },
      })
      .from(".get-mirror header", {
        opacity: 0,
        y: 40,
      })
      .from(".get-mirror .glide__slide ", {
        opacity: 0,
        y: 100,
        duration: 1.5,
        stagger: 0.2,
      })
  }

  if (document.querySelector(".mirror-types")) {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".mirror-types",
          start: "top 70%",
          // toggleActions: "restart none reverse reverse",
        },
      })
      .from(".mirror-types header", {
        opacity: 0,
        y: 40,
      })
      .from(".mirror-types .lch-cart", {
        opacity: 0,
        y: 100,
        duration: 1.5,
        stagger: 0.2,
      })
  }

  if (document.querySelector(".mirror-by-space")) {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".mirror-by-space",
          start: "top 70%",
          // toggleActions: "restart none reverse reverse",
        },
      })
      .from(".mirror-by-space header", {
        opacity: 0,
        y: 40,
      })
      .from(".mirror-by-space .lch-cart", {
        opacity: 0,
        y: 100,
        duration: 1.5,
        stagger: 0.2,
      })
  }

  if (document.querySelector(".blog-posts")) {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".blog-posts",
          start: "top 70%",
          // toggleActions: "restart none reverse reverse",
        },
      })
      .from(".blog-posts header", {
        opacity: 0,
        y: 40,
      })
      .from(".blog-posts .lch-cart", {
        opacity: 0,
        y: 100,
        duration: 1.5,
        stagger: 0.2,
      })
  }

  if (document.querySelector(".related.products")) {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".related.products",
          start: "top 70%",
          // toggleActions: "restart none reverse reverse",
        },
      })
      .from(".related.products .heading-underline", {
        opacity: 0,
        y: 40,
      })
      .from(".related.products .lch-cart.product", {
        opacity: 0,
        y: 100,
        duration: 1.5,
        stagger: 0.2,
      })
  }
}
