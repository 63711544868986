export default () => {
  const mobileMenu = document.querySelector("#mobile-menu"),
    mobileFolded = document.querySelector("#mobile-folded-menu")

  if (mobileMenu && mobileFolded) {
    const toggleBtn = mobileMenu.querySelector("button"),
      returnBtn = document.querySelector("#mobile-nav .mobileNav__return")

    toggleBtn.addEventListener("click", () => {
      mobileMenu.classList.add("move")
      mobileFolded.classList.add("move")
      returnBtn.classList.add("active")
    })

    returnBtn.addEventListener("click", () => {
      mobileMenu.classList.remove("move")
      mobileFolded.classList.remove("move")
      returnBtn.classList.remove("active")
    })
  }
}
