export default () => {
  const fileInputCF7 = document.querySelector("input.wpcf7-file")

  if (fileInputCF7) {
    const insertBefore = document.querySelector(
        "span[class*='file']"
      ).nextSibling,
      label = fileInputCF7.closest("label")

    fileInputCF7.addEventListener("click", () => {
      label.classList.add("active")
    })

    fileInputCF7.addEventListener("change", () => {
      const fileName = document.createElement("p")
      const inputToChange = label.querySelector("p.file-name")

      if (inputToChange) {
        label.removeChild(inputToChange)
      }

      fileName.classList.add("file-name")
      fileName.textContent = fileInputCF7.files[0].name
      label.insertBefore(fileName, insertBefore)

      label.classList.remove("active")

      // fileInputCF7.files.forEach(item => {
      //   fileName.classList.add("file-name")
      //   fileName.textContent = item.name
      //   inputWrapperCF.insertBefore(fileName, insertBefore)
      // })
    })
  }
}
