export default () => {
  const tabsButtons = document.querySelectorAll(
    ".accordion button[data-toggle]"
  )

  if (tabsButtons) {
    tabsButtons.forEach((tabsButton) => {
      tabsButton.addEventListener("click", () => {
        // const tabToClose = document.querySelector(
        //   'button[aria-expanded="true"]'
        // )

        // if (tabToClose && tabsButton !== tabToClose) {
        //   closeTab(tabToClose)
        // }

        if (tabsButton.getAttribute("aria-expanded") === "false") {
          openTab(tabsButton)
        } else {
          closeTab(tabsButton)
        }
      })
    })
  }
}

const openTab = (button) => {
  const target = button.getAttribute("aria-controls"),
    panel = document.querySelector(`#${target}`)

  button.setAttribute("aria-expanded", "true")
  button.style.pointerEvents = "none"

  panel.classList.add("show")
  panel.classList.add("collapsing")

  setTimeout(() => {
    const desiredHeight = panel.children[0].offsetHeight
    panel.style.height = desiredHeight + "px"
  })

  panel.addEventListener(
    "transitionend",
    () => {
      panel.classList.remove("collapsing")
      panel.style = ""
      button.style.pointerEvents = "auto"
    },
    {
      once: true,
    }
  )
}

const closeTab = (button) => {
  const target = button.getAttribute("aria-controls"),
    panel = document.querySelector(`#${target}`),
    desiredHeight = panel.children[0].offsetHeight

  button.setAttribute("aria-expanded", "false")
  button.style.pointerEvents = "none"
  panel.style.height = desiredHeight + "px"

  setTimeout(() => {
    panel.classList.add("collapsing")
    panel.style = ""
  })

  panel.addEventListener(
    "transitionend",
    () => {
      panel.classList.remove("collapsing")
      panel.classList.remove("show")
      button.style.pointerEvents = "auto"
    },
    {
      once: true,
    }
  )
}
