export default () => {
  const select = document.querySelector(
    "#custom-select-f .option.defaultOption"
  )

  if (select) {
    const optionsContainer = document.querySelector(".options-container"),
      selectBox = document.querySelector(".select-box"),
      options = document.querySelectorAll(".option:not(.defaultOption)"),
      inputsList = optionsContainer.querySelectorAll("input"),
      visibleInput = select.querySelector(".option.defaultOption p.label")

    //function to use in listener
    const detectClickOutside = (e) => {
      if (e.target && !selectBox.contains(e.target)) {
        selectBox.classList.toggle("active")
        optionsContainer.classList.toggle("active")
        document.body.removeEventListener("click", detectClickOutside)
      }
    }

    select.addEventListener("click", () => {
      selectBox.classList.toggle("active")
      optionsContainer.classList.toggle("active")

      document.body.addEventListener("click", detectClickOutside)
    })

    //location change, depend on choosen element

    options.forEach((item) => {
      const location = item.querySelector("input").value
      item.addEventListener("click", () => {
        window.location.replace(location)
      })
    })

    //setup default option

    const currenLocation = window.location.href
    let isEmpty = true

    inputsList.forEach((item) => {
      if (item.value.includes(currenLocation)) {
        visibleInput.textContent =
          item.parentNode.querySelector("label").textContent
        item.parentNode.style.display = "none"
        isEmpty = false
      }
    })

    if (isEmpty && !currenLocation.includes("sklep")) {
      visibleInput.textContent = "Wybierz kategorię"
    }
  }
}
